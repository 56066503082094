import { Box, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import CatPrintRight from "../images/singlePrintRight.png";
import CatPrintLeft from "../images/singlePrintLeft.png";
export default function CatPricingInformation() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        marginTop: isMobile && 55,
      }}
    >
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: "#F5F7F3",
          padding: isMobile ? "10px" : "60px",
          marginBottom: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintRight}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            All bookings are priced by the day and include check in and check
            out days.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintLeft}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            A minimum of 3 days is required for each booking.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <img
            src={CatPrintRight}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            We are closed for check in and check out on Wednesdays, Sundays and
            Bank Holidays.
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={CatPrintLeft}
            alt="Green cat print"
            style={{ height: "60px", verticalAlign: "middle" }}
          />
          <Typography
            style={{
              paddingLeft: "20px",
              color: "#3e443b",
            }}
            variant="h5"
          >
            Prices for Christmas Day and Boxing Day will be double the normal
            daily rate.
          </Typography>
        </div>
      </Box>
    </Box>
  );
}
