import React, { useState } from "react";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import FacebookIcon from "@mui/icons-material/Facebook";

export default function MenuComponent() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/">
          Home
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/about-us">
          About Us
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/pricing">
          Pricing
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/gallery">
          Gallery
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/contact-us">
          Contact Us
        </MenuItem>
      </Menu>
      <IconButton
        color="inherit"
        aria-label="menu-burger"
        aria-controls="menu-appbar"
        onClick={handleMenu}
      >
        <MenuIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem color="white" sx={{ m: 1 }} />
      <IconButton
        color="inherit"
        target="_blank"
        href="https://www.facebook.com/share/WjcTcAaPjvZNn5Cb/?mibextid=LQQJ4d"
      >
        <FacebookIcon backgroundColor="red" color="red" />
      </IconButton>
    </>
  );
}
