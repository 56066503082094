import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function HowToBook() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      <Typography
        textAlign="center"
        variant="h3"
        style={{
          fontFamily: "Playfair Display",
          color: "#667b68",
          margin: 10,
          marginBottom: 40,
        }}
      >
        How To Book:
      </Typography>
      <Box
        sx={{
          borderRadius: "20px",
          backgroundColor: "#F5F7F3",
          padding: isMobile ? "20px" : "60px",
          marginBottom: 5,
          display: "flex",
        }}
      >
        <Box>
          <Typography variant="h5" mb={2}>
            To book a chalet please{" "}
            <Button
              href="https://www.caricapets.co.uk/Dashboard/Account/CustomerLogin/07aaeae2-ddda-423e-a4a2-903d2352cdc3"
              variant="contained"
              target="blank"
              sx={{
                backgroundColor: "#92BB80",
                "&:hover": {
                  backgroundColor: "#6C8B5E",
                },
              }}
            >
              Book Here
            </Button>
          </Typography>
          <Typography variant="h5">
            You will be taken to our external booking provider Carica Pets where
            you can register as a customer, add details about your cats, check
            availability and make a booking.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
