import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const FrontSplash = () => {
  return (
    <Box p={5} mt={2}>
      <Typography variant="h5">Welcome to Newton Regis Cattery</Typography>
      <Typography mt={3} mb={3}>
        We are a small family run cattery located in a beautiful rural setting
        just outside Newton Regis, north-east of Tamworth. <br />
        Our facilities consist of 17 uPVC double-glazed chalets each able to
        accommodate 2 cats from the same household. A selection of these chalets
        have removeable connecting doors to accommodate a family of up to 4
        cats.
      </Typography>
      <Box style={{ display: "flex" }}>
        <Typography
          variant="h5"
          style={{
            fontFamily: "Playfair Display",
            fontWeight: "bolder",
            color: "#2A3C2B",
          }}
        >
          To register your details, check availability or make a booking visit{" "}
          <Typography
            variant="h5"
            to="https://www.caricapets.co.uk/Dashboard/Account/CustomerLogin/07aaeae2-ddda-423e-a4a2-903d2352cdc3"
            component={Link}
            target="blank"
            sx={{
              fontFamily: "Playfair Display",
              fontWeight: "bolder",
              color: "#92BB80",
              ml: 0.5,
              textDecoration: "none",
              "&:hover": {
                color: "#6C8B5E",
              },
            }}
          >
            {"  "}
            here *
          </Typography>
        </Typography>
      </Box>
      <Typography variant="subtitle2" sx={{ mt: 1 }}>
        *please note that clicking this link will open our external booking
        provider Carica Pets.
      </Typography>
    </Box>
  );
};
